/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement("p", {
    className: "py-2"
  }, React.createElement(_components.p, null, "Before I realized my brain had ADHD, I always believed my physical health was perfect. But in the week of my ADHD diagnosis, I began to question the thoughts and beliefs in my brain, while the discomfort in my body became hard to ignore."), React.createElement(_components.p, null, "This is the first part of the relationship between ADHD and physical sensations.")), "\n", React.createElement("h3", null, "Ignoring: I'm Fine, I'm Okay"), "\n", React.createElement("p", {
    className: "py-2"
  }, React.createElement(_components.p, null, "From a young age, I had a false belief in my brain: I should be a perfect person, and my body should be 100% healthy. Any illness should be temporary, caused by external factors, and impossible to persist long-term."), React.createElement(_components.p, null, "With this belief, I often regarded my physical symptoms as illusions. When I was in kindergarten and playing with other kids, I fell to the ground. I told everyone I was fine, even though I was silently crying. I slept like that for a night, only to find the next day that I couldn't lift my right hand, and went to the hospital with my parents for a check-up. The result was a fracture of the right clavicle."), React.createElement(_components.p, null, "Other instances of ignoring include: rarely feeling thirsty and drinking little water. Rarely feeling hunger and fullness, leading to overeating or skipping meals. Since a day at the age of 15, my vision has been filled with bright spots ", React.createElement("a", {
    href: "https://en.wikipedia.org/wiki/Visual_snow_syndrome"
  }, "See: Visual Snow Syndrome"), ", initially causing a lot of depression, but later adapting and forgetting the existence of this symptom.")), "\n", React.createElement("h3", null, "Deceiving: Maybe This Isn't Acting"), "\n", React.createElement("p", {
    className: "py-2"
  }, React.createElement(_components.p, null, "My ADHD brain often puts on a drama by itself. In the eyes of the ADHD brain, I am a sophisticated actress, skilled at using my body to portray characters or achieve goals."), React.createElement(_components.p, null, "For example, I feel nauseous and retch against the wall, and my brain thinks I'm portraying a pregnant woman in early pregnancy. My head hurts so much that I can't open my eyes and have to be helped to the dormitory because I'm performing to attract my friends' attention."), React.createElement(_components.p, null, "Because the performance activities in my mind are temporary, and these physical symptoms indeed do not persist, I firmly believe in the identity of a \"sophisticated actress.\"")), "\n", React.createElement("h3", null, "Misattributing: The Hypothesis of Anxiety Somatization"), "\n", React.createElement("p", {
    className: "py-2"
  }, React.createElement(_components.p, null, "At the start of my first full-time job, I experienced my first \"anxiety somatization.\" It was a morning of remote work, I finished a morning meeting and drank McDonald's coffee."), React.createElement(_components.p, null, "When I wanted to walk back to my room from the living room, my heart suddenly raced, and my breathing became rapid. I took deep breaths until my hands and lower body became numb, and my boyfriend moved me to the bed."), React.createElement(_components.p, null, "I recalled a scene of hyperventilation from a previous documentary. My brain gave such hyperventilation, rapid heartbeat, limb numbness, and trembling a name: anxiety somatization. I went to the clinic for anti-anxiety medication and cried to the doctor, explaining the mental stress of starting a formal job."), React.createElement(_components.p, null, "Before my appointment with a psychiatrist for an ADHD diagnosis, I or my brain began to be anxious about it, creating frequent self-perceived \"anxiety somatization\" or \"panic attack\" situations. I dug out expired anti-anxiety medication, attempting to use serotonin to correct my emotions, but this time, it caused me low mood and depression."), React.createElement(_components.p, null, "Later, my psychiatrist stated: ADHD can indeed trigger intense anxiety and depression. This gave me a new attribution for my physical discomfort: ADHD.")), "\n", React.createElement("h4", null, "Follow-up"), "\n", React.createElement("p", {
    className: "py-2"
  }, React.createElement(_components.p, null, "The diagnosis of ADHD, cognitive behavioral therapy, and mindfulness meditation began to change my understanding of my brain and body. If you are interested in these topics, please continue reading: ", React.createElement("a", {
    href: "../../blog/know-your-body-2"
  }, "Know Your Body (Part 2): Mindfulness Meditation, Cognitive Behavioral Therapy, and Medication"))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
