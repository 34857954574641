/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement("p", {
    className: "py-2"
  }, React.createElement(_components.p, null, "在我意识到我的脑子拥有ADHD之前，我一直相信我的身体健康状态是完美的。但在诊断的那一周，我开始质疑我的大脑中的想法和信念，与此同时，我身体的不适感已经变得难以忽视。"), React.createElement(_components.p, null, "这是ADHD与身体感受关系的上篇。")), "\n", React.createElement("h4", null, "忽视：我很好，我没事"), "\n", React.createElement("p", {
    className: "py-2"
  }, React.createElement(_components.p, null, "从很小的时候开始，我大脑中便有这样一个错误的信念：我应当是完美的人，我的身体应当100%的健康。任何疾病都应当是暂时性的，有外部因素引起，并且不可能长期的存在。"), React.createElement(_components.p, null, "怀着这个信念，我时常将我的身体症状当成是幻觉。我幼儿园时期和其他小孩打闹时，我摔倒在地。我向大家表示一切如常，尽管我在无声地流泪。我就这样睡了一晚，知道第二天发现我无法举起右手，才和父母一同前往医院检查。检查的结果是右侧锁骨骨折。"), React.createElement(_components.p, null, "类似的忽视情况还有：很少感受口渴，并很少喝水。很少感受饥饿和饱腹，因此过量进食或跳过一餐。自15岁的某天起，我的视野中充满亮的斑点（请查看：", React.createElement("a", {
    href: "https://en.wikipedia.org/wiki/Visual_snow_syndrome"
  }, "视雪症"), "），开始时因此产生大量抑郁情绪，后来却适应并忘记了这个症状的存在。")), "\n", React.createElement("h4", null, "欺骗：或许这不是表演"), "\n", React.createElement("p", {
    className: "py-2"
  }, React.createElement(_components.p, null, "我的ADHD大脑，时常自顾自地上演戏剧。在ADHD大脑的眼中，我是个高级的表演艺术家，擅长运用身体饰演人物，或达成目的。"), React.createElement(_components.p, null, "例如，我感到恶心，对着墙壁干呕，我的大脑认为是我在饰演孕早期的孕妇。我头部神经痛得无法睁开双眼，只能靠人搀扶到寝室，是因为我在通过表演，吸引朋友的注意。"), React.createElement(_components.p, null, "因为我头脑中表演活动是暂时的，而这些身体症状也确实并不会持续，我对于“高级表演艺术家”的身份深信不疑。")), "\n", React.createElement("h4", null, "错误归因：关于焦虑躯体化的假说"), "\n", React.createElement("p", {
    className: "py-2"
  }, React.createElement(_components.p, null, "在我人生中第一份全职工作刚开始时，我经历了第一次“焦虑躯体化”。那是一个远程办公的早上，我开完早会，并喝了麦当劳的咖啡。"), React.createElement(_components.p, null, "当我想要从客厅走回我的房间时，我的心跳忽然加快，呼吸也变得急促。我大口大口的吸气，直到我的手和下半身变得麻木，被男朋友转移到床上。"), React.createElement(_components.p, null, "我回想起之前某部纪录片中，过度呼吸的场景。我的脑子给这样的过度呼吸、心跳加速、肢体麻木和颤抖起了个名字：焦虑躯体化。我前往诊所开抗焦虑的药物，并对着医生大哭，阐述刚开始正式工作的精神压力。"), React.createElement(_components.p, null, "在我前往精神科医生确诊ADHD的预约之前，我或是我的脑子开始对此焦虑，并给我制造了频繁的自认为的“焦虑躯体化”或“惊恐发作”状况。我又翻出已经过期的抗焦虑药物，企图利用血清素对我的情绪进行修正，但这一次，却给我造成了低落和抑郁。"), React.createElement(_components.p, null, "后来我的精神科医生表示：ADHD的确可能诱发强烈的焦虑、抑郁情绪。这才给我一个新的身体不适的归因对象：ADHD。")), "\n", React.createElement("h4", null, "后续"), "\n", React.createElement("p", {
    className: "py-2"
  }, React.createElement(_components.p, null, "对ADHD的确诊、认知行为疗法与正念冥想，使我对大脑和身体的认识开始改变。如果你对这些话题感兴趣，请继续阅读：", React.createElement("a", {
    href: "../../blog/zh/know-your-body-2"
  }, "了解你的身体（下）：正念冥想、认知行为疗法与药物治疗"))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
